import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import HeaderGeneric from '../../components/HeaderGeneric'

class Generic extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="श्री गुरूगजाननलीला ग्रंथ - अध्याय १३ वा" />
        <HeaderGeneric title="श्री गुरूगजाननलीला ग्रंथ" adhyaay='अध्याय १३ वा' num='13'/>
        <div id="main">
          <section id="content" className="main">
            <p>
            श्री गणेशाय नम: । हे विधात्या परमेश्वरा । धावून यावे सत्वरा । तुम्हीच आमचे रक्षण करा ॥ १ ॥
तूच निर्मितो ब्रह्मांडास । कोण थांबवणार कृतीस । जे जे तुझ्या मनास । तेच निर्माणास ॥ २ ॥
सजीव निर्जीव सुरासुर । भाव जे जे निराकार । करतोस तू ते साकार । सारे तुझ्यावरच अवलंबणार ॥ ३ ॥
महत्व देतो पूर्वसंचितास । जैसे ज्याच्या वाट्यास । तैसे होते आचरणास । उगाच न तुला दोष ॥ ४ ॥
जैशी ज्याची करणी । तैशी त्याची भरणी । गणामहाराज जाणे मनोमनी । तूच चालव लेखणी ॥ ५ ॥
ब्रह्मदेवा उत्पत्तीकारा । सरस्वतीस निर्मिणारा । तूच वाटतो आधार खरा । ग्रंथ लेखनास सहाय्य करा ॥ ६ ॥
मी जाणतो लीलांना । जरी तीची प्रेरणा । न उत्पत्ती तुझ्याविणा । कैसा विसरू ह्या क्षणा ॥ ७ ॥
जो हे तत्व विसरणार । तो स्वतःचा घात करणार । एवढा मूर्ख नसणार । जेणे आधार ठेवणार ॥ ८ ॥
हे जाणणार्‍या ब्रह्मदेवा । किती तुज विनवावा । मनोरथ पूर्ण व्हावया । सहाय्यभूत व्हावया ॥ ९ ॥
न श्रवणावीण लेखन । हे जाणतो मनोमन । ऐसा नाद करवावा श्रवण । जो उमटेल लेखनातून ॥ १० ॥
तूच नाद उत्पन्न केला । गणामहाराजे ओळखला । जेणे तुज बोलवला । भक्तांस तोषवायला ॥ ११ ॥
कर निमंत्रण स्विकार । जे शब्द निराकार । करावेत साकार । ग्रंथास ठरतील आधार ॥ १२ ॥
तूच शिकवले आम्हांस । गुरू प्राप्तीत आनंद मनास । उत्पन्न केले आनंदास । देता झाला आम्हांस ॥ १३ ॥
एकाच्या उपासनेत सर्व मिळते । उगाच का मंदिर बांधले जाते । ज्याचे जीवन आदर्श असते । त्याचेच स्मारक बांधले जाते ॥ १४ ॥
जे कृतज्ञ असणार । ते कृतघ्न नसणार । ऐसे होते शेगावकर । कैसे महाराजांना सोडणार ॥ १५ ॥
ते त्यांचा शब्द झेलणार । त्यांना पदोपदी जपणार । त्यातच हित मानणार । जीवन सुसंगत करणार ॥ १६ ॥
एक एकर जागा मिळाली । समर्थ वाणी खरी ठरली । पुढील कार्यास सुरुवात झाली । कार्यकारणीची बैठक झाली ॥ १७ ॥
जो कार्य करणार । तो स्वस्थ न बसणार । तो उद्यमीच रहाणार । एकातुन अनेक होणार ॥ १८ ॥
जे जे करायचे निर्माण । त्यास लागे साधक साधन । साकार निराकारातून । न होई त्यावाचून ॥ १९ ॥
समर्थांचे भक्त होते । मठ बांधणे नक्की होते । साधन गोळा करत होते । त्या साठी झटत होते ॥ २० ॥
सृष्टीत सारे असेच दिसते । एकामुळे प्रेरणा मिळते । दुसर्‍यांस ती प्रवृत्त करते । सृष्टीचे चक्र ऐसेच चालते ॥ २१ ॥
आदेश कुणाचा । तो होता महाराजांचा । तो आदर्शच ठरायचा । का न तो ठरायचा ? ॥ २२ ॥
भक्तांचे चालती प्रयत्न । नाना तर्‍हेचे भेटती जन । नाना तर्‍हेचे नाना प्रश्र्न । उगाच विनाकारण ॥ २३ ॥
होत नसते बडबडण्याने । होत असते कृतीने । काही तरी दिल्याने । भाव व्यक्त केल्याने ॥ २४ ॥
जो आनंद मिळवल्याने । तोच मिळे तो दिल्याने । पटते अनुभवाने । घ्यावा अनुभव प्रत्येकाने ॥ २५ ॥
समयानुसार द्यावे लागते । तेच उचित ठरते । तहानलेल्यास अन्न देण्याते । कधी न तहान शमते ॥ २६ ॥
पाणीच द्यावे लागते । उपाशाला अन्न द्यावे लागते । अज्ञान्यास ज्ञान लागते । निर्धनास धन लागते ॥ २७ ॥
तेल उपयुक्त ज्योतीस । औषधोपचार रोग्यास । उमटेल ऐसे लेखनास । चुना वीटा बांधकामास ॥ २८ ॥
एकट्याने न काही होणार । एकटा काय करणार । दुसर्‍याची मदत लागणार । परस्पर भाव लागणार ॥ २९ ॥
वर्गणीस सुरुवात झाली । आस्तिक नास्तिक टक्कर झाली । भक्तांची चाचणी झाली । उत्तीर्ण होता वर्गणी मिळाली ॥ ३० ॥
नास्तिकांनी नकार दिला । त्यांनी भक्तांस प्रश्न केला । गुरू करती योगलीला । का हो निघालात वर्गणीला ॥ ३१ ॥
ज्याला काही करायचे नसते । त्याचे बोलणे असेच असते । ज्याला काही करायचे असते । त्याचे हातून घडत असते ॥ ३२ ॥
विधात्याचा नियम न मोडणार । तो एका हाताने देणार । दुसर्‍या स्वरूपात घेणार । कुणीही न त्यास अडवणार ॥ ३३ ॥
तत्व कळले भक्तांना । म्हणाले नास्तिकांना । देहास संपत्ती जाणा । आत्म्यास भक्ती जाणा ॥ ३४ ॥
जो आचरतो तत्वास । तरतो भवसागरात । जन्म मरण देहास । नच कधी आत्म्यास ॥ ३५ ॥
मरण निश्चित संपत्तीस । नच मरण भक्तीस । जाणावे शाश्वतास । जवळ करावे त्यास ॥ ३६ ॥
समर्थांचे भक्त बोलणार । ते हृदयातलेच बोलणार । समर्पकच बोलणार । ते प्रभावीच असणार ॥ ३७ ॥
भुलवावे वाणी प्रभावाने । सत्‌शील वृत्तीने । सत्‌वृत्ती निःस्वार्थाने । परोपकारी वृत्तीने ॥ ३८ ॥
ऐसे जे कर्म होते । तेच प्रभावी ठरते । महाराजांचे भक्त हुशार होते । तेणे ऐसे बोलत होते ॥ ३९ ॥
हा मठ आमचा नव्हे । हा मठ आपलाच आहे । आमचे जगात काय आहे ? । सर्व आपलेच आहे ॥ ४० ॥
माझा आणि आपला । फरक त्यांना कळला । तोच कृतीत उतरवला । कार्यभाग साधला ॥ ४१ ॥
माझे माझे म्हणण्यात । काही न अर्थ जडे त्यात । तुझे तुझे म्हणण्यात । खरा अर्थ जगण्यात ॥ ४२ ॥
नास्तिकांचे अज्ञान । दूर गेले पळून । भक्तांवर प्रभावीत होऊन । ज्ञानी झाले त्यातून ॥ ४३ ॥
वर्गणी देण्यास तयार झाले । आस्तिक नास्तिक एक झाले । ऐक्य भाव जागृत झाले । उत्साही वातावरण दिसले ॥ ४४ ॥
ऐक्यातच सारे मिळाले । तत्व अनुभवास आले । मठाचे काम सुरू झाले । योग्यते प्रमाणे काम विभागले ॥ ४५ ॥
हे केवळ भक्तांचे नव्हे । आपलेही कर्तव्य आहे । हा मठ सर्वांचा आहे । माझा एकट्याचा नव्हे ॥ ४६ ॥
त्यांच्यात “मी” जन्मतःच नव्हता । तो भाव कृतीत होता । सच्चिदानंदाच्या मठा । कार्य करावे लागे समर्था ॥ ४७ ॥
मनोमनी जाणत होते । स्वस्थ बसवत नव्हते । चोख करायचे असते । हेच त्यांना माहित होते ॥ ४८ ॥
उत्स्फूर्तपणे उठले । रेतीच्या गाडीत बसले । गाडी हाकू लागले । मठाकडे गाडीस नेले ॥ ४९ ॥
काहिंचे कार्य बांधकामाचे । काहिंचे देखरेखीचे । महत्व तितकेच दोघांचे । समर्थांचे देखरेखीचे ॥ ५० ॥
बाकिच्यांचे बांधकामाचे । हे सुद्धा निश्चित असते । वेळेवेळेनुसार घडते । विधात्यापुढे न चालते ॥ ५१ ॥
बांधकामाच्या जागी ऐसे बसले । ते तेथुन मुळिच न उठले । भक्तांनी त्यांस विचारले । सर्वांना पेचात टाकले ॥ ५२ ॥
जागा फक्त एक एकराची । चर्चा चाले भक्तांची । आज्ञा समर्थांची । त्यानुसार कामे बांधकामाची ॥ ५३ ॥
समर्थ हेच केंद्रस्थान । तेच प्रमाण मानून । बांधकाम करता जाण । परिसर होईल छान ॥ ५४ ॥
जागेची मापणी केली । अकरा गुंठे कमी पडली । अधिकार्‍यास विनवणी केली । तोंडोतोंडी बोलणी झाली ॥ ५५ ॥
अधिकारी विरघळले । आणखीन एक एकर देते झाले । मौखिक संमती देते झाले । तेच घातक ठरले ॥ ५६ ॥
मौखिकाला किंमत नसते । कागदोपत्राला किंमत असते । अनेक वेळा अनुभवास येते । तैसेच येथे घडते ॥ ५७ ॥
कुटाळांनी अर्ज केला । अकरा गुंठे आरोप केला । फसवले सरकारला । बळकावले जागेला ॥ ५८ ॥
भक्तगण विचारात । समर्थांस निवेदितात । विपरित प्रसंगात । तारण्यास विनवितात ॥ ५९ ॥
सर्व कळले समर्थांस । वदले आपल्या भक्तांस । दंड न होईल तुम्हास । निश्चिंत असावे मनास ॥ ६० ॥
जोशी अधिकार्‍यांनी । जागेची केली तपासणी । प्रेरणा दिली समर्थांनी । वेगळेच आले घडुनी ॥ ६१ ॥
जोशांनी शेरा मारला । जो दंड संस्थानला । माफी असावी त्याला । संस्थान न कारण दंडाला ॥ ६२ ॥
दंडाची रक्कम परत । ऐसी झाली हुकुमत । संतोषले सर्व भक्त । खरे ठरती समर्थ ॥ ६३ ॥
सरकारकडून परत रक्कम । तितुके सोपे नसे काम । समर्थ कृपा होती ठाम । तेणे सहज झाले काम ॥ ६४ ॥
समर्थांचे सामर्थ्य जाणले । चरणी लोटांगण  घातले । वृत्त सर्वत्र पसरले । आश्चर्य सर्वांस वाटले ॥ ६५ ॥
दूरदूरचे भक्त येऊ लागले । नाना नवस बोलू लागले । नवसास ते पावतील वाटले । तेणे नवस बोलले गेले ॥ ६६ ॥
जो उन्हाने त्रस्त होणार । ज्याला ताप असह्य होणार । तोच सावलीचा आधार घेणार । दुसर्‍यांस किंमत नसणार ॥ ६७ ॥
विधात्याने निर्मिले दोन्ही । उपयोगाविणा न राहे कुणी । आवश्यकतेवाचुनी । न उपयुक्त क्षणी ॥ ६८ ॥
समयानुसार आसरा घेतो । आपला कार्यभाग साधतो । कधी मायेची ऊब मागतो । कधी दयेचा पाझर शोधतो ॥ ६९ ॥
जेव्हा एखादा व्याधीग्रस्त होतो । मुक्त होण्या इलाज करतो । औषधी इलाज निकामा ठरतो । समर्थांकडे धाव घेतो ॥ ७० ॥
सवडदचा गंगाभारती । प्रारब्ध भोग आडवे येती । नाना दुखणी त्याप्रती । मुख्य महारोग त्याप्रती ॥ ७१ ॥
सुंदर चांगले मिळावे आपणास । असेच वाटते प्रत्येकास । देखणी पत्नी हवी पतीस । सुदृढ पती हवा पत्नीस ॥ ७२ ॥
जो तो हिणवतो कुरूपाला । न कधी थारा कुरूपाला । स्थान असते गोर्‍याला । दूर लोटतात काळ्याला ॥ ७३ ॥
देह म्हटला की रोग होणार । जसाचा तसा न रहाणार । कधी सौम्य आजार । त्यातूनच कधी तीव्र होणार ॥ ७४ ॥
काही जन्मभर सतावणारे । औषधाने न बरे होणारे । परी औषध करावे लागणारे । देह असे पर्यंत टिकणारे ॥ ७५ ॥
गंगाभारतीस महारोग । साधा नसून महारोग । हातापायास जाते भेग । अंग होते भंग ॥ ७६ ॥
एक एक अवयव होतो क्षीण । जाणला जातो संसर्गजन्य । कोणीच न जवळ करणार जन । नको नको होते जीवन ॥ ७७ ॥
पूर्वसंचिते व्याधी झाली । ताप टाळण्यासाठी घेतली । कल्पवृक्षाची सावली । शेगावची वाट धरली ॥ ७८ ॥
गंगाभारतीचा आवाज पहाडी । गायनात वरचढी । परी तो न कुणा आवडी । झिडकारला जाई हरघडी ॥ ७९ ॥
दर्शनाची तळमळ फार । समर्थच वाटती आधार । परी शिष्यांचा परिवार । जवळ कैसा जाऊ देणार ॥ ८० ॥
करे भजनाचा गजर । धाव घेत होती नजर । भोग संपल्याचा सूर । ऐकण्या झाला अधीर ॥ ८१ ॥
अखेर सुदिन आला । प्रारब्ध भोग संपला । समर्थांच्या दृष्टीस पडला । त्यांचे चरणी पडला ॥ ८२ ॥
समर्थे मारले त्यास थोबाडीत । खाकरले क्षणभरात । बेडके टाकले त्याप्रत । हेच औषध महारोगाप्रत ॥ ८३ ॥
काही जनांनी पाहिले । विचित्रच वाटले । किळसवाणी वाटले। दुसर्‍यांस न खपले ॥ ८४ ॥
जगात दृष्टीला महत्व फार । जैसी दृष्टी पहाणार । तैसे मनात येणार । कृती त्यावर अवलंबणार ॥ ८५ ॥
तितुकेच महत्व श्रद्धेला । हृदयातिल भोळ्या भावाला । तेणे न किंतू मनाला । कधी न येणार त्याला ॥ ८६ ॥
सगळे जरी एकच पहाणार । परी दृष्टीस वेगळे दिसणार । जो जैसा पहाणार । तैसे त्यास दिसणार ॥ ८७ ॥
धन सत्ता आणि स्त्रीस । दृष्टीने पहाल जैसे त्यांस । तैसेच दृष्टोत्पत्तीस । नको वृथा दोष त्यांस ॥ ८८ ॥
समर्थ करती जेथे स्नान । तेथली माती अंगास लावून । तेच औषध समजून । गंगाभारती जगे जीवन ॥ ८९ ॥
तेथील एका साधूस । न पटले त्याच्या मनास । बोलला गंगाभारतीस । नको माती लावू अंगास ॥ ९० ॥
किती खुळा आहेस । माती अंगास फासतोस । औषध प्रमाण मानतोस । परी न उपयुक्त रोगास ॥ ९१ ॥
साधूने गंगास हटकले । करू नकोस ऐसे चाळे । साधूस साधे न कळले । जग कशातून निर्मिले ॥ ९२ ॥
परिक्षा घ्याया श्रद्धेची । ईच्छा साधूच्या मनाची । घेऊ तिथल्या मातीची । घेऊ तिच्या वासाची ॥ ९३ ॥
माती घेता त्या स्थानाची । दुर्गंधी ठरली साधूची । सुगंधी ठरली गंगाची । महती पटली श्रद्धेची ॥ ९४ ॥
असेच गेले पंधरा दिवस । माती लावली अंगास । मावळत गेले रोगास । फरक पडला कायेस ॥ ९५ ॥
पुढे भेटण्याला आला । संतोषभारती पुत्र भला । तसेच पत्नी सोबतीला । खबरबात काढायला ॥ ९६ ॥
दोघे विनवू लागले त्याला । आपल्या घरी न्यायला । दुखण्याला उतार पडला । जाऊ आता परतीला ॥ ९७ ॥
गंगाभारतीस कळला सार । संतसंगतीत रमण्यात सार । ज्ञानी पुढेच जाणार । कधी न मागे फिरणार ॥ ९८ ॥
गंगाभारतीने नकार दिला । तुम्ही जावे आपल्या घराला । मी येथे एकटाच भला । राहीन आता शेगावला ॥ ९९ ॥
गंगाभारती गेला नाही । कृपासावली सोडली नाही । मोहमाया ओळखली । न पडला त्यांच्या जाळी ॥ १०० ॥
महाराजांचे अनेक भक्त । समर्थां सांगे मनोगत । त्यांना घरी नेत असत । हेतू साधित असत ॥ १०१ ॥
असाच मुंडगावचा । झ्यामसिंग नावाचा । भक्त महाराजांचा । हेतू घरी नेण्याचा ॥ १०२ ॥
समर्थे मान्य केले । मुंडगावात गेले । नाना भक्त जमले । पूजन विधी करू लागले ॥ १०३ ॥
शुद्ध हेतू पूजनाचा । भंडारा घालण्याचा । समर्थ सेवा करण्याचा । त्यात आनंद मानायचा ॥ १०४ ॥
सुदिन असावा सुयोगास । माहित नव्हते झ्यामसिंगास । रिक्ततिथी चतुर्दशीस । नक्की केले भंडार्‍यास ॥ १०५ ॥
हे न रुचले समर्थांस । वदले झ्यामसिंगास । नको भंडारा रिक्ततिथीस । भंडारा करावा पोर्णिमेस ॥ १०६ ॥
पुढिल प्रसंग जाणला । परी न कळले झ्यामसिंगाला । स्वतःचा हेका पूर्ण केला । ऐन वेळी गोंधळ झाला ॥ १०७ ॥
पर्जन्याने घोटाळा केला । मुसळधार पाऊस पडला । भंडारा अर्धवट राहिला । झ्यामसिंग बावरुन गेला ॥ १०८ ॥
समर्थांचे चरण धरले । समर्थांस विनविले । समर्थे पर्जन्यास रोकले । पर्जन्ये त्यांचे ऐकले ॥ १०९ ॥
समर्थांनी सांगितलेल्या तिथीस । म्हणजेच पोर्णिमेस । पूर्णत्व भंडार्‍यास । जाणले समर्थ अधिकारास ॥ ११० ॥
पर्जन्यास रोकवावे भक्तासाठी । केवढी गुरूंची कृपादृष्टी । गुरू भक्तांचे हित चिंतिती । वेळोवेळी जागृत करिती ॥ १११ ॥
पोर्णिमेस भंडारा । पूर्ण झाला खरा । झ्यामसिंगास आनंद न्यारा । हर्षभरीत झाला चेहेरा ॥ ११२ ॥
मुंडगावी महाराजांचे । भक्त अनेक होते त्यांचे । त्यातल्या एका भक्ताचे । नाव पुंडलीक त्याचे ॥ ११३ ॥
उकिरड्याचा पुत्र पुंडलीक । होता एकुलता एक । उत्तम संस्काराचे पीक । दावे कृती एकेक ॥ ११४ ॥
प्रत्येक महिन्याच्या वद्य पक्षात । जावे गुरूमंदिरात । संकल्प पूर्ण होण्यात । अनेक अडथळे येतात ॥ ११५ ॥
निष्ठा कामी येते । हेतू पूर्ण करण्याते । संकटे दुर्लक्ष करण्याते । हेतू साध्य होते ॥ ११६ ॥
प्लेगची साथ आली । एकेकास घेरू लागली । पुंडलीकास केले जवळी । आपत्ती आली मोठी ॥ ११७ ॥
आपत्तीतून मार्ग काढणारा । ध्येयाचे लक्ष्य गाठणारा । विचलीत न होणारा । आदर्श ठरतो खरा ॥ ११८ ॥
पुंडलीक ठरतो त्यात । होता कृतनिश्चयात । पायी वारी करण्यात । शेगावी जाण्यात ॥ ११९ ॥
अंगातला ताप जाईना । अशक्तपणा जाईना । वारी केल्यावाचून राहवेना । चित्तातील ओढीस रोकवेना ॥ १२० ॥
मायबापाने समजावले त्यास । आपण करू गाडी घोड्यास । त्याने जाऊ शेगावास । गुरुंच्या दर्शनास ॥ १२१ ॥
प्रबळ ईच्छा शक्तीने । दृढ एकनिष्ठेने । मनोनिर्धाराने जगण्यार्‍या पुंडलीकाने । नकार दिला मानेने ॥ १२२ ॥
मी पायीच वारी करणार । शेगावी तसाच जाणार । वाटेत मरण आल्यावर । ठेवा मज गुरूचरणावर ॥ १२३ ॥
माता पिता चिंतेत पडले । पुत्र हट्ट न मोडता झाले । सर्वजण पायीच निघाले । मनोमनी विनवू लागले ॥ १२४ ॥
स्वारी तशीच निघाली । काखेतली गाठ दुखू लागली । हळवी करण्यास सांगू लागली । गुरूकृपा शोधू लागली ॥ १२५ ॥
एक एक पाऊल पुढे पडत होते । सत्वपरिक्षेस मागे टाकत होते । कोण त्यात जिंकते । हेच जणू पहात होते ॥ १२६ ॥
गुरू निष्ठेच्या जोरावर । प्रबळ मनोनिर्धारावर । कापीत होते अंतर । जणु लक्ष्य ध्येयपूर्तीवर ॥ १२७ ॥
जगात टिकत काही नाही । दृष्य प्रहर अवस्थांचे ऐसेच होई । नियमातून सुटत नाही । ध्येयासच महत्व राही ॥ १२८ ॥
संकट येते नि जाते । निवारण्याचे महत्व टिकते । त्यास तोंड देण्याते । ध्येय गाठले जाते ॥ १२९ ॥
हीच शिकवण पुंडलीकास । तीच आली उपयोगास । तिघे आले मठास । त्यांनी धरले गुरूचरणास ॥ १३० ॥
समर्थे सर्व जाणले । आपल्याच काखेस दाबले । पुंडलीकाच्या गाठेस हळवे केले । त्याचे दुखणे पार पळाले ॥ १३१ ॥
दुखण्यास चिकटून रहाण्यास । कधी न आवडे त्यास । जाणीव करते दुखणार्‍यास । त्याचेवर इलाज करण्यास ॥ १३२ ॥
कारणास्तव दुखणे येते । कालांतराने पळून जाते । योग सुयोगाचे होते नाते । जे गुरूकृपेने जमते ॥ १३३ ॥
गुरू मानतात जोडण्यात । नाही कधी तोडण्यात । ते भक्त सदा जोडतात । शत्रूसही मित्र करतात ॥ १३४ ॥
गुरूकृपा पुंडलीकास । प्लेग गाठ निवारण्यास । ऐशी गुरूकृपा लाभो भक्तांस । संकट निवारण्यास ॥ १३५ ॥
भक्तांचे हेतू पूर्ण करण्यात । समर्थांस व्हावे लागे रत । ही कथा पुढिल अध्यायात । दंग व्हाल वाचण्यात ॥ १३६ ॥


॥ शुभं भवतु ॥
॥ श्री गणपती सरस्वतीर्पणमस्तु ॥
॥ इति श्रीगुरूगजाननलीला ग्रंथस्य त्रयोदशोऽध्यायः संपूर्णः ॥
</p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Generic
